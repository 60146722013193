import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { iif, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toCamelCase } from '@utils/snakeToCamel';
import { fixObjectKeys } from '@utils/fixObjectKeys';
import { toSnakeCase } from '@utils/toSnakeCase';

const changeParams = (params: HttpParams): HttpParams => {
	let newParams = new HttpParams();

	params.keys().forEach(k => {
		params.getAll(k)?.forEach(v => {
			newParams = newParams.append(toSnakeCase(k), v);
		});
	});

	return newParams;
};

@Injectable({
	providedIn: 'root',
})
export class CamelCaseInterceptorService implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return iif(
			() => request.body instanceof FormData,
			next.handle(request),
			next.handle(
				request.clone({
					body: Object.assign({}, fixObjectKeys(toSnakeCase)(request.body)),
					params: changeParams(request.params),
				}),
			),
		).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					if (event.body instanceof Blob || event.body instanceof ArrayBuffer) {
						return event;
					}

					return event.clone({
						body: Array.isArray(event.body)
							? event.body.map(fixObjectKeys(toCamelCase))
							: fixObjectKeys(toCamelCase)(event.body),
					});
				}

				return event;
			}),
		);
	}
}
