import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { errorFromBack, recoverAccount } from '../../chat/utils/errorFromBack';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	private auth = inject(AuthService);

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.addToken(request)).pipe(
			catchError((err: HttpErrorResponse) => {
				if (errorFromBack(err)?.includes(recoverAccount)) {
					this.auth.isActive = false;
				}

				if (!request.url.includes('refresh') && err.status === 401 && err.error.code === 'token_not_valid') {
					if (!this.auth.refreshToken) return this.logout(err, true);

					return this.auth.refreshTokens().pipe(
						switchMap((data: HttpResponse<any>) => {
							if (data.status === 200) {
								this.auth.setToken(data.body);

								return next.handle(this.addToken(request)).pipe(catchError(e => throwError(() => e)));
							}

							return this.logout(err, true);
						}),
						catchError(err => this.logout(err)),
					);
				}

				return this.logout(err);
			}),
		) as any;
	}

	logout(err: HttpErrorResponse, dontCheckError = false) {
		if (err.status === 401 || dontCheckError) {
			this.auth.logout();
		}

		return throwError(() => err);
	}

	addToken(request: HttpRequest<any>): HttpRequest<any> {
		const headers = this.auth?.accessToken
			? request.headers.set('Authorization', `Bearer ${this.auth.accessToken}`)
			: request.headers;

		return request.clone({
			headers,
		});
	}
}
