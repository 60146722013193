import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UrlInterceptorService implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestUrl = request.url.startsWith('/') ? request.url.slice(1) : request.url;

		return next.handle(
			request.clone({
				url: request.url.startsWith('https') ? request.url : [environment.apiURL, requestUrl].join('/'),
			}),
		);
	}
}
