import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '@services/auth.service';

export const AuthGuard = () => {
	const auth = inject(AuthService);
	const router = inject(Router);

	const tokenValid = !!auth.accessToken && auth.hasRole();

	if (!tokenValid) {
		router.navigateByUrl('/auth/login');
	}

	return of(tokenValid);
};
