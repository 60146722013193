import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ToastPackage, ToastrService, Toast } from 'ngx-toastr';

@Component({
	selector: 't-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent extends Toast {
	protected override toastrService = inject(ToastrService);

	public override toastPackage = inject(ToastPackage);
}
