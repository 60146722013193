export const fixObjectKeys = (transform: (str: string) => string) => {
	return (obj: any): any => {
		if (!obj || !Object.keys(obj).length || typeof obj !== 'object') return obj;

		return Object.fromEntries(
			Object.entries(obj).map(([k, v]) => [
				transform(k),
				Array.isArray(v)
					? v.map(fixObjectKeys(transform))
					: typeof v == 'object'
					? fixObjectKeys(transform)(v)
					: v,
			]),
		);
	};
};
