import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { T_ICONS_PATH } from './tokens';
import { TStaticRequestService } from './static-request.service';
import { map, shareReplay } from 'rxjs/operators';
import { StringHandler, symbolToSvg } from './utils';

@Injectable({
	providedIn: 'root',
})
export class TIconService {
	private cache = new Map<string, Observable<string | null>>();

	constructor(
		@Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
		@Inject(T_ICONS_PATH) private path: StringHandler<any>,
		@Inject(TStaticRequestService)
		private readonly staticRequestService: TStaticRequestService,
	) {}

	getIcon(src: string): Observable<string | null> {
		if (src.includes('<svg')) {
			return of(src);
		}

		if (src.includes('.svg')) {
			return this.staticRequestService.request(src);
		}

		return this.staticRequestService.request(this.path(src)).pipe(
			// преобразовываем символ в свгшку, работать с ними одинаково
			map(res => symbolToSvg(res, this.path(src))),
		);
	}

	getIconCached(src: string): Observable<string | null> {
		const cache = this.cache.get(src);

		if (cache) {
			return cache;
		}

		const piped = this.getIcon(src).pipe(shareReplay(1));

		this.cache.set(src, piped);

		return piped;
	}
}
