import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	Input,
	QueryList,
} from '@angular/core';
import { startWith } from 'rxjs/operators';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { IconComponent } from '../icon/icon.component';

export type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl';
export type ButtonTheme = 'primary' | 'secondary' | 'ghost' | 'raised' | 'inline' | 'link' | 'dashed';

@Component({
	selector: 't-button, [tButton]',
	templateUrl: './button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./button.component.sass'],
	host: {
		'[class.t-button]': 'true',
		'[class.t-button--size-xs]': 'size === "xs"',
		'[class.t-button--size-s]': 'size === "s"',
		'[class.t-button--size-m]': 'size === "m"',
		'[class.t-button--size-l]': 'size === "l"',
		'[class.t-button--size-xl]': 'size === "xl"',

		'[class.t-button--theme-primary]': 'theme === "primary" || !theme',
		'[class.t-button--theme-secondary]': 'theme === "secondary"',
		'[class.t-button--theme-ghost]': 'theme === "ghost"',
		'[class.t-button--theme-raised]': 'theme === "raised"',
		'[class.t-button--theme-inline]': 'theme === "inline"',
		'[class.t-button--theme-link]': 'theme === "link"',
		'[class.t-button--theme-dashed]': 'theme === "dashed"',
	},
	standalone: true,
})
export class ButtonComponent implements AfterContentInit {
	@Input() size: ButtonSize = 'm' as ButtonSize;
	@Input('tButton') theme: ButtonTheme | '' = 'primary' as ButtonTheme;

	@ContentChildren(IconComponent) icons!: QueryList<IconComponent>;

	constructor(private elementRef: ElementRef) {}

	private _inline = false;

	get inline(): boolean {
		return this._inline;
	}

	@Input() set inline(value: BooleanInput) {
		this._inline = coerceBooleanProperty(value);
	}

	ngAfterContentInit(): void {
		const parent = this.elementRef.nativeElement;
		const nodesLength = parent.childNodes.length;

		this.icons.changes.pipe(startWith(this.icons)).subscribe(() => {
			this.icons.forEach(icon => {
				const el = icon.elementRef.nativeElement;
				el.classList.remove('t-button__icon--last', 't-button__icon--first');

				el.classList.add('t-button__icon');

				if (nodesLength === 1) {
					parent.classList.add('t-button--icon');
					return;
				}

				if (parent.lastChild === el) {
					el.classList.add('t-button__icon--last');
					return;
				}

				if (parent.firstChild === el) {
					el.classList.add('t-button__icon--first');
				}
			});
		});
	}
}
