import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, share } from 'rxjs';

interface UrlInterface {
	previous?: string;
	current?: string;
}

@Injectable({
	providedIn: 'root',
})
export class RoutesService implements OnDestroy {
	subject = new BehaviorSubject<UrlInterface>({});

	setSubject(value: UrlInterface) {
		this.subject.next(value);
	}

	getSubject() {
		return this.subject.asObservable().pipe(share());
	}

	ngOnDestroy() {
		this.subject.complete();
	}
}
