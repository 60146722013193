import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// (window as any).sockets = [];
// const nativeWebSocket = window.WebSocket;
// (window as any).WebSocket = function (...args: any) {
// 	const socket = new nativeWebSocket(...args);
// 	(window as any).sockets.push(socket);
// 	return socket;
// };

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
