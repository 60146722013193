import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core';
import { TIconService } from './icon.service';
import { tap } from 'rxjs/operators';
import { getSize } from './utils';

@Component({
	selector: 't-icon',
	template: '',
	styleUrls: ['./icon.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.t-icon]': 'true',
	},
	standalone: true,
})
export class IconComponent {
	get src(): string | undefined {
		return this._src;
	}

	@Input('icon') set src(value: string | undefined) {
		if (this._src === value || !value) {
			return;
		}

		this._src = value;

		this.svgService
			.getIconCached(value)
			.pipe(
				tap(src => {
					if (!src) {
						throw Error(`Could not load icon with name ${this.src}`);
					}
				}),
			)
			.subscribe(src => {
				if (!src) {
					return;
				}
				this.innerSrc = src;
				this.updateSize();
				this.elementRef.nativeElement.innerHTML = src;
			});
	}

	private _src?: string;

	get size(): string | number | null | undefined {
		return this._size;
	}

	@Input() set size(value: string | number | null | undefined) {
		this._size = value;
		this.updateSize();
	}

	private _size?: string | number | null;

	private innerSrc?: string;

	constructor(
		@Inject(TIconService) private readonly svgService: TIconService,
		@Inject(ElementRef) public readonly elementRef: ElementRef<Element>,
		private renderer: Renderer2,
	) {}

	updateSize(): void {
		if (!this.innerSrc) {
			return;
		}
		const [iconWidth, iconHeight] = getSize(this.innerSrc);
		const width = +(this._size || iconWidth || 24);
		const height = +(this._size || iconHeight || 24);

		this.renderer.setAttribute(this.elementRef.nativeElement, 'style', `width: ${width}px; height: ${height}px`);
	}
}
