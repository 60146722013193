import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule as CdkDialogModule } from '@angular/cdk/dialog';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { IconComponent } from '@components/icon/icon.component';
import { DialogCloseDirective } from './dialog-close.directive';

@NgModule({
	declarations: [DialogHeaderComponent, DialogBodyComponent, DialogFooterComponent, DialogCloseDirective],
	imports: [CommonModule, CdkDialogModule, IconComponent],
	exports: [CdkDialogModule, DialogHeaderComponent, DialogBodyComponent, DialogFooterComponent, DialogCloseDirective],
})
export class DialogModule {}
