import { HttpErrorResponse } from '@angular/common/http';

const notObjectCheck = (value: any, fallback: string) => {
	if (Array.isArray(value)) return checkForValidString(value?.[0], fallback);
	else if (typeof value === 'string') return value;

	return fallback;
};

const checkForValidString = (val: any, fallback: string): string | undefined => {
	if (typeof val === 'string' && val.toLocaleLowerCase().includes('html')) return fallback;

	if (typeof val === 'object') {
		const value = Object.values(val)?.[0];

		return notObjectCheck(value, fallback);
	}

	return notObjectCheck(val, fallback);
};

export const errorFromBack = (err: HttpErrorResponse | string, fallback: string = 'Error') => {
	return checkForValidString(typeof err === 'string' ? err : err.error, fallback);
};

export const recoverAccount = 'Recover your account';
