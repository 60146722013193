import { inject, Injectable } from '@angular/core';
import { LocalStorage, storageService } from '@utils/local-storage';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { RolesInterface, Token, TokenInterface } from '../models/token';
import { UserInfo } from '../models/user-info';
import { Observable } from 'rxjs';
import { ToastService } from './toast.service';
import { UserTypeEnum } from '@models/generated';
import { fixObjectKeys } from '@utils/fixObjectKeys';
import { toCamelCase } from '@utils/snakeToCamel';
import { removeAllEmpty } from '@utils/removeEmpty';
import { PersonalInfo } from '../../profile/account/models/personalInfo';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	@LocalStorage()
	accessToken: string | undefined | null;
	@LocalStorage()
	cookiesAgreement: string | undefined | null;
	@LocalStorage()
	refreshToken: string | undefined | null;
	@LocalStorage()
	roles: RolesInterface | undefined | null;
	@LocalStorage()
	companyStatus: string | undefined | null;
	@LocalStorage()
	managerEmail: string | undefined | null;
	@LocalStorage()
	isActive: boolean | undefined;
	@LocalStorage()
	userInfo: UserInfo | null | undefined;

	private router = inject(Router);
	private http = inject(HttpClient);
	private toast = inject(ToastService);

	get isBuyer() {
		return this.roles?.includes(UserTypeEnum.BUYER) ?? false;
	}

	get isSeller() {
		return this.roles?.includes(UserTypeEnum.SELLER) ?? false;
	}

	get isAnalyst() {
		return this.roles?.includes(UserTypeEnum.ANALYST) ?? false;
	}

	get isAdmin() {
		return this.roles?.includes(UserTypeEnum.ADMINISTRATOR) ?? false;
	}

	get isLawyer() {
		return this.roles?.includes(UserTypeEnum.LAWYER) ?? false;
	}

	get isSalesManager() {
		return this.roles?.includes(UserTypeEnum.SALES_MANAGER) ?? false;
	}

	get isUKUser() {
		return this.isAdmin || this.isSalesManager || this.isAnalyst || this.isLawyer;
	}

	get isIdentified() {
		return this.userInfo?.identified ?? false;
	}

	get isCompanyVerified() {
		return this.companyStatus === 'verified' ?? false;
	}

	get companyId() {
		return this.userInfo?.companyId ?? 0;
	}

	get firstRole(): UserTypeEnum | undefined {
		return this.roles?.[0];
	}

	get isManagementCompany() {
		return this.isAnalyst || this.isAdmin || this.isSalesManager || this.isLawyer;
	}

	get parsedToken() {
		return fixObjectKeys(toCamelCase)(JSON.parse(atob(this.accessToken?.split('.')[1] ?? ''))) as TokenInterface;
	}

	accountInfo() {
		return this.http.get<PersonalInfo>('/api/personal-account/');
	}

	hasRole(): boolean {
		return Boolean(this.roles);
	}

	login(body: { email: string; password: string }) {
		return this.http.post<Token>('api/auth/obtain/', body);
	}

	authorize(response: Token): void {
		this.setToken(response, true);
	}

	setToken(response: Token, authorization = false) {
		const backupRefresh = this.refreshToken;
		const backupIsActive = this.isActive;
		const backupCookiesAgreement = this.cookiesAgreement;

		storageService.clearStorage();
		this.accessToken = response.access ?? '';
		this.refreshToken = response?.refresh ?? backupRefresh;

		const parsedToken = this.parsedToken;
		this.roles = parsedToken.roles;
		this.companyStatus = parsedToken.companyStatus;
		this.managerEmail = parsedToken.ameeteeChatMail;
		this.isActive = backupIsActive;
		this.cookiesAgreement = backupCookiesAgreement;

		this.userInfo = removeAllEmpty({
			...parsedToken,
			tokenType: undefined,
			exp: undefined,
			iat: undefined,
			jti: undefined,
			ameeteeChatMail: undefined,
			companyStatus: undefined,
		});

		this.accountInfo().subscribe({
			next: res => {
				this.isActive = res?.isActive !== undefined ? res?.isActive : true;

				if (res?.company?.status) this.companyStatus = res?.company.status;
				if (authorization) this.router.navigateByUrl('/profile');
			},
			error: (err: HttpErrorResponse) => {
				if (authorization) {
					this.isActive = false;
					if (err.status === 403) {
						this.router.navigateByUrl('/profile');
					} else {
						this.toast.error(err);
						this.router.navigateByUrl('/auth/login');
					}
				}
			},
		});
	}

	refreshTokens(): Observable<HttpResponse<{ access: string }>> {
		return this.http.post<{ access: string }>(
			`/api/auth/refresh/`,
			{ access: this.accessToken, refresh: this.refreshToken },
			{ observe: 'response' },
		);
	}

	refreshAccess(access: string): void {
		this.accessToken = access;
	}

	logout(): void {
		storageService.clearStorage();
		this.router.navigateByUrl('/auth/login');
	}
}
