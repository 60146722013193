import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CamelCaseInterceptorService } from '@services/camel-case-interceptor.service';
import { UrlInterceptorService } from '@services/url-interceptor.service';
import { ToastComponent } from '@components/toast/toast.component';
import { IconComponent } from '@components/icon/icon.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptorService } from '@services/auth-interceptor.service';
import { DialogModule } from './modules/dialog/dialog.module';
import { CookieComponent } from '@components/cookie/cookie.component';
import { ButtonComponent } from '@components/button/button.component';

@NgModule({
	declarations: [AppComponent, ToastComponent, CookieComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		IconComponent,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center',
			toastComponent: ToastComponent,
			preventDuplicates: true,
		}),
		DialogModule,
		ButtonComponent,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CamelCaseInterceptorService,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
