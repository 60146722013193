import { Component, inject } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { RoutesService } from '@services/routes.service';

@Component({
	selector: 't-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass'],
})
export class AppComponent {
	constructor() {
		const routesService = inject(RoutesService);

		inject(Router)
			.events.pipe(
				filter(event => event instanceof RoutesRecognized),
				startWith({}),
				pairwise(),
			)
			.subscribe(evt => {
				const events = evt as RoutesRecognized[];

				routesService.setSubject({
					previous: 'urlAfterRedirects' in events[0] ? events[0].urlAfterRedirects : '',
					current: events[1]?.urlAfterRedirects,
				});
			});
	}
}
